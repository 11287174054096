/**
 * Our Quote-block
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import * as styles from './quote.module.scss';
import Block from 'components/Block';
import QuoteIcon from './quote.inline.svg';
import PropTypes from 'prop-types';

const Quote = ({quote: {text, name, position}, ...props}) => (
    <Block Parent={'blockquote'} {...props}>
        <div className={styles.inner}>
            <QuoteIcon className={styles.quoteIcon}/>
            <p className={styles.text}>{text}</p>
            { (name || position) &&
                <p className={styles.person}>
                    {(name) && <span className={styles.name}>{name}</span>}
                    {(position) && <span>{position}</span>}
                </p>
            }
        </div>
    </Block>
);

// PropTypes
Quote.propTypes = {
    quote: PropTypes.shape({
        text: PropTypes.string.isRequired,
        name: PropTypes.string,
        position: PropTypes.string,
    }).isRequired,
};

Quote.defaultProps = {
    quote: {
        name: '',
        position: '',
    },
};


export default Quote;
