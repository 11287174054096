/**
 * Our grid element
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Peritus
import PeritusLink, {PeritusLinkProps} from '../../components/PeritusLink';
import ResponsiveImage, {ResponsiveImageProps} from '../../components/ResponsiveImage';
import Block from 'components/Block';

// Styles
import * as styles from './grid.module.scss';
import {wide} from 'components/Block/block.module.scss';

const Grid = ({cards, ...props}) => (
    <Block className={wide} {...props}>
        <div className={classnames({[styles.grid]: true, [wide]: true})}
            style={{
                '--grid-count': Math.min(3, cards.length),
                '--grid-count-sm': Math.min(2, cards.length),
            }}
        >
            {
                cards && cards.map((element, key) => {
                    const {
                        link,
                        image,
                    } = element;

                    return (
                        <PeritusLink key={key} className={styles.card} activeClassName={styles.current} {...link}>
                            <p className={styles.title}>
                                <span className={styles.titleText}>{link.linktext}</span>
                            </p>
                            <ResponsiveImage className={styles.image} image={image} style={{position: 'absolute'}}/>
                        </PeritusLink>
                    );
                })
            }
        </div>
    </Block>
);

Grid.propTypes = {
    cards: PropTypes.arrayOf(PropTypes.shape({
        link: PropTypes.shape(PeritusLinkProps).isRequired,
        image: PropTypes.shape(ResponsiveImageProps).isRequired,
    })).isRequired,
};

export default Grid;
